import PropTypes from "prop-types"
import React from "react"

const HeroFull = ({ children }) => (
	<div id='heroFull' style={{height: '100%'}}>
		<div>{children}</div>
	</div>
)

HeroFull.propTypes = {
  siteTitle: PropTypes.string,
}

HeroFull.defaultProps = {
  siteTitle: ``,
}

export default HeroFull

