import React from "react"

import { Link } from 'gatsby'
import { Link as Anchor } from 'react-scroll'

import { graphql } from "gatsby"
import Img from "gatsby-image"

import HeroFull from "../components/heroFull.js"
import MaxWidth from "../components/maxWidth.js"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"


const IndexPage = (props) => (  
	 <>
	  <HeroFull className='index'> 
	  	<h1>Employee Assistance Help &amp; Resources</h1>
	  	<p style={{marginBottom: '30px'}}>Counseling and Referral for Employees and Their Families</p>
			<Anchor className='btn primary' to='employee' activeClass="active" spy={true} smooth={true} offset={-90} duration= {750}>I Am an Employee</Anchor>
			<Anchor className='btn primary' to='employer' activeClass="active" spy={true} smooth={true} offset={-90} duration= {750}>I Am an Employer</Anchor>
			<br/>
	  	{/*<Anchor className='cta' to='about' activeClass="active" spy={true} smooth={true} offset={-90} duration= {750}>What is an EAP?</Anchor>}*/}
	  </HeroFull> 
	  <Layout>
	    <SEO title="Employee Assistance Help &amp; Resources" keywords={['Corporate EAP Resources',`What is an EAP`,`Employee Assistance`, `Employee Services`, `Boston EAP`, `Boston Employee Help`]} description="Corporate EAP Resources has been helping businesses &amp; individuals for over 30 years, providing counseling services &amp; resources throughout Boston and New England." />
		<MaxWidth>
			<h2 id='about'>What Is an EAP?</h2>
			<p><strong><dfn><abbr title="Employee Assistance Program">EAP</abbr></dfn></strong> stands for <i>Employee Assistance Program</i>, a benefit for employees to help with personal and/or work-related issues that could affect job performance, health, mental or emotional well-being.</p> 
			<p>Corporate EAP Resources has been helping businesses and individuals for over 30 years, providing counseling services and access to helpful resources throughout Boston and New England.</p>
		
	    
	    <div className='container-50-50'>
		    <div className='item' id='employee'>
		    	<h2>If You Are an Employee That Needs Help</h2>
				<p>Our professionals are clinicians (Masters-level or higher) specializing in mental health counseling. All contact with an EAP clinician is confidential, no names or concerns are disclosed to your employer. It is free to you and your family, a benefit provided by your company.</p>
				
		    	<Link className='btn primary' to='/for-individuals'>Benefits for Employees</Link><br/>
		    	<Link to="/privacy" className='btn secondary' style={{marginLeft:'0px', marginTop: '0px'}}>Learn More About Privacy</Link>
		    	
		    </div>
			<div className='item'>
			<Img fluid={props.data.imageArch.childImageSharp.fluid} alt='Employee'/>
			</div>
			
	    </div>
	    <div className='container-50-50'>
	    	<div className='item'>
				<Img fluid={props.data.imageDesk.childImageSharp.fluid} alt='Employer'/>
			</div>
	    	<div className='item' id='employer'>
	    		<h2>If You Are an Employer</h2>
	    		<p>Ensuring the wellness of your organization is no easy task. We work to provide customized plans for our clients that are unique and helpful for both employees and businesses.</p>
					<p>EAP services are not limited to occurrences within the workplace. Corporate EAP Resources works to provide holistic plans where necessary, in order to help employees at home or in the workplace.</p>
	    		
	    		<Link to='/for-business' className='btn primary' style={{marginLeft:'0px',}}>Benefits for Employers</Link><br/>	
	    	</div>
	    </div>
		</MaxWidth>
	  </Layout>
	  <script src="./js/script.js"></script>
	 </>
	 
)

export const pageQuery = graphql`
  query {
    imageArch: file(relativePath: { eq: "arch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageDesk: file(relativePath: { eq: "at-desk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage